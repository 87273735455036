<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5">
        <v-card class="elevation-12 pb-1">
          <v-toolbar dark flat>
            <v-toolbar-title>{{
              $t("forms.deleteConfirmation.title")
            }}</v-toolbar-title>
          </v-toolbar>
          <v-alert v-if="complete" class="ma-4" dense outlined type="success">
            {{ $t("forms.deleteConfirmation.success") }}
          </v-alert>

          <v-form v-else @submit.prevent="submitForm">
            <v-card-title>
              {{ $t("forms.deleteConfirmation.confirmation") }}
            </v-card-title>

            <v-alert v-if="error" class="ma-4" dense outlined type="error">
              {{ $t(error) }}
            </v-alert>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn type="submit" color="error" :loading="loading">
                {{ $t("forms.deleteConfirmation.submit") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";

export default {
  name: "DeleteConfirmation",
  data() {
    return {
      loading: false,
      error: null,
      complete: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    ...mapActions({
      deleteAccount: "user/deleteAccount",
      logoutUser: "user/logoutUser"
    }),
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    submitForm() {
      this.success = null;
      this.error = null;

      if (!this.loading) {
        this.loading = true;
  
        if (this.$route.query.token) {
          const token = this.$route.query.token;
          const parsedToken = this.parseJwt(token);
          const userId = parsedToken.jti;

          const payload = {
            data: {
              id: userId,
              token
            }
          };

          this.deleteAccount(payload)
            .then(response => {
              this.loading = false;

              if (response.data.status !== 0) {
                return Promise.reject(response);
              }
              
              this.complete = true;

              if (this.user.id === userId) {
                this.logoutUser();
              }
            })
            .catch(response => {
              this.loading = false;

              switch (response.data.status) {
                case -1010:
                  this.error = "forms.deleteConfirmation.errors.deletionFailed";
                  break;
                default:
                  this.error = "errors.unexpectedError";
              }
            });
        } else {
          this.loading = false;
          this.error = "forms.deleteConfirmation.errors.invalidCredentials";
        }
      }
    }
  }
};
</script>
